<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
            Agent Performance
            <v-icon right> mdi-gauge </v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="table.headers"
            :items="allPerformance"
            show-group-by
            group-by="session.quota"
            :loading="allPerformance.length == 0"
          >
            <template v-slot:item.session.date="{item}">
                {{ item.session.date | formatDate }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      table: {
        headers: [
          { text: "Session Date", value: "session.date" },
          { text: "Session Quota", value: "session.quota" },
          { text: "Agent", value: "agent.name" },
          { text: "Total Contacted", value: "total" },
        ],
      },
    };
  },
  methods: {
    ...mapActions(["fetchPerformance"]),
  },
  computed: {
    ...mapGetters(["allPerformance"]),
  },
  filters: {
    formatDate: (date) => {
      return moment(date).format("dddd, Do MMM YYYY");
    },
  },
  mounted() {
    this.fetchPerformance();
  },
};
</script>